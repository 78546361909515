import React, { useState } from "react";
import Footer from "../components/footer";
import HeroSection from "../components/hero-section";
import ConnectWallet from "../components/modals/connect-wallet";
import EarlyAccess from "../components/modals/early-access";
import Menu from "../components/modals/menu";
import SucceModal from "../components/modals/success";
import WhiteList from "../components/modals/whitelist";
import NavBar from "../components/navbar";
import styles from "../styles/presale.module.scss";

function PrivatePresale() {
  const [showModal, setShowModal] = useState("none");
  return (
    <div className={styles.presale_con}>
      {showModal === "earlyaccess" && (
        <EarlyAccess setShowModal={setShowModal} />
      )}
      {showModal === "success" && <SucceModal setShowModal={setShowModal} />}
      {showModal === "whitelist" && <WhiteList setShowModal={setShowModal} />}
      {showModal === "connectwallet" && (
        <ConnectWallet setShowModal={setShowModal} />
      )}
      {showModal === "menu" && <Menu setShowModal={setShowModal} />}

      <NavBar setShowModal={setShowModal} />
      <HeroSection setShowModal={setShowModal} />
      <Footer />
    </div>
  );
}

export default PrivatePresale;

import PrivatePresale from "./pages/private-presale";

function App() {
  return (
    <div className="App">
      <PrivatePresale />
    </div>
  );
}

export default App;

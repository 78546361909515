import React from "react";
import styles from "../styles/button.module.scss";

function Button({ text, action, link }) {
  return (
    <div
      className={styles.button_con}
      onClick={() => {
        action();
      }}
    >
      <div className={styles.button_inner_con}>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noreferrer noopener"
            style={{ textDecoration: "none", color: "white" }}
          >
            {text}
          </a>
        ) : (
          text
        )}
      </div>
    </div>
  );
}

export default Button;

import React from "react";
import styles from "../styles/footer.module.scss";
import logo from "../assets/logo.svg";

function Footer() {
  return (
    <div className={styles.footer_con}>
      <div className={styles.footer_inner_con}>
        <div>
          <img src={logo} alt="" />
          <h5>
            Multi Protocol Decentralized <br /> Exchange on Cardano.
          </h5>
        </div>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/"
            >
              About us
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/roadmap/"
            >
              Roadmap
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/tokenomics/"
            >
              Tokenomics
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://github.com/bashoswap-inc/"
            >
              Github
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/whitepaper"
            >
              Whitepaper
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/terms-and-conditions/"
            >
              Terms & Conditions
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://bashoswap.finance/team.html/"
            >
              Team
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/hiring/"
            >
              Hiring
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/contact/"
            >
              Contact us
            </a>
          </li>
        </ul>
        <span></span>
      </div>
      <span>Copyright 2022 © All Rights Reserved</span>
    </div>
  );
}

export default Footer;

import React from "react";
import styles from "../../styles/modal.module.scss";
import Button from "../button";
import close_btn from "../../assets/close-btn.svg";

function WhiteList({ setShowModal }) {
  return (
    <div className={styles.modal_con}>
      <div className={styles.whitelist}>
        <img
          src={close_btn}
          alt="close"
          className={styles.close_btn}
          onClick={() => {
            setShowModal("none");
          }}
        />
        <h1>Join our whitelist </h1>
        <p>
          The end goal of bashoswap is simply to create a permissionless
          exchange that would include a liquidity / swapping protocol and In
          total there will be 500 Million $Bash tokens minted.
        </p>
        <h5>Bash Distribution </h5>
        <div className={styles.stats_con}>
          <div className={styles.stats_comp}>
            <h2>Total Supply</h2>
            <h6>500,000,000</h6>
          </div>
          <div className={styles.stats_comp}>
            <h2>Minimum Contribution Amount</h2>
            <h6>20,000 ADA</h6>
          </div>
          <div className={styles.stats_comp}>
            <h2>Private Sale Allocation</h2>
            <h6>100,000,000</h6>
          </div>
          <div className={styles.stats_comp}>
            <h2>Private Sale Price</h2>
            <h6>0.055 ADA</h6>
          </div>
        </div>
        <div className={styles.button_wrapper}>
          <Button
            text="Next"
            action={() => {
              setShowModal("earlyaccess");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default WhiteList;

import React from "react";
import styles from "../../styles/modal.module.scss";
import nami from "../../assets/namilogo.png";
import Button from "../button";
import close_btn from "../../assets/close-btn.svg";

function ConnectWallet({ setShowModal }) {
  return (
    <div className={styles.modal_con}>
      <div className={styles.connect_wallet}>
        <img
          src={close_btn}
          alt="close"
          className={styles.close_btn}
          onClick={() => {
            setShowModal("none");
          }}
        />
        <img src={nami} alt="close" />
        <h1>Connect your Nami wallet</h1>
        <p>To continue, you have to connect your nami wallet.</p>

        <span>
          <Button
            text="Connect Wallet"
            action={() => {
              setShowModal("none");
            }}
          />
        </span>
      </div>
    </div>
  );
}

export default ConnectWallet;

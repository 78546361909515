import React from "react";
import styles from "../../styles/menu.module.scss";
import close_btn from "../../assets/close-btn.svg";
import logo from "../../assets/logo.svg";

function Menu({ setShowModal }) {
  return (
    <div className={styles.modal_con}>
      <div className={styles.menu_con}>
        <img
          src={close_btn}
          alt="close"
          className={styles.close_btn}
          onClick={() => {
            setShowModal("none");
          }}
        />
        <div>
          <img src={logo} alt="" />
        </div>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/"
            >
              Overview
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/faq"
            >
              FAQs
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/contact"
            >
              Talk To Us
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://docs.bashoswap.finance/hiring"
            >
              Careers
            </a>
          </li>
          <li
            className={styles.connec_wallet}
            onClick={() => {
              setShowModal("connectwallet");
            }}
          >
            Connect Wallet
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;

import React from "react";
import styles from "../../styles/modal.module.scss";
import close_btn from "../../assets/success.svg";
import telegram from "../../assets/telegram.svg";
import twitter from "../../assets/twitter.svg";
import Button from "../button";

function SucceModal({ setShowModal }) {
  return (
    <div className={styles.modal_con}>
      <div className={styles.succes_con}>
        <img src={close_btn} alt="close" />
        <h1>You have successfully joined Bashoswap private-sale whitelist</h1>
        <p>
          To increase your chances of getting selected, follow us on twitter and
          join Bashoswap Telegram channel
        </p>
        <div className={styles.social_con}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://t.me/bashoswap"
          >
            <img src={telegram} alt="telegram" />
          </a>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://twitter.com/basho_swap"
          >
            <img src={twitter} alt="twitter" />
          </a>
        </div>
        <span>
          <Button
            text="Return to homepage"
            action={() => {
              setShowModal("none");
            }}
            link="https://bashoswap.finance/"
          />
        </span>
      </div>
    </div>
  );
}

export default SucceModal;

import React, { useState } from "react";
import styles from "../styles/hero-section.module.scss";
import Button from "./button";
import { useEffect } from "react";

function HeroSection({ setShowModal }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [whiteListeUsers, setWhiteListeUsersState] = useState(0);
  // const [hasExpired, setHasExpired] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimer();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  function updateTimer() {
    let future = Date.parse("mar 25, 2022 00:00:00");
    let now = new Date();
    let diff = future - now;

    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor(diff / (1000 * 60 * 60));
    let mins = Math.floor(diff / (1000 * 60));
    let secs = Math.floor(diff / 1000);

    setDays(days);
    setHours(hours - days * 24);
    setMinutes(mins - hours * 60);
    setSeconds(secs - mins * 60);
  }

  const getWhiteListedUser = () => {
    fetch("https://peaceful-tundra-06709.herokuapp.com/api/v1/users")
      .then((response) => response.json())
      .then((data) => setWhiteListeUsersState(data.data.length));
  };

  useEffect(() => {
    getWhiteListedUser();
  }, []);

  return (
    <div className={styles.hero_con}>
      <div className={styles.hero_inner_con}>
        <div className={styles.hero_left}>
          <span>
            <h5>{whiteListeUsers} whitelisted!</h5>🎉
          </span>
          <h1>Bash Private Sale Whitelist</h1>
          <h3>Private sale starts in:</h3>
          <div className={styles.timer_con}>
            <div>
              <h1>{days}</h1>
              <h4>Days</h4>
            </div>
            <div>
              <h1>{hours}</h1>
              <h4>Hours</h4>
            </div>
            <div>
              <h1>{minutes}</h1>
              <h4>Minutes</h4>
            </div>
            <div>
              <h1>{seconds}</h1>
              <h4>Seconds</h4>
            </div>
          </div>
          <div className={styles.button_wrapper}>
            <Button
              text="Join whitelist"
              action={() => setShowModal("whitelist")}
            />
            <Button text="Join Community" link="https://t.me/bashoswap" />
          </div>
        </div>
        <div className={styles.video_con}>
          <iframe
            width="850"
            height="444"
            src="https://www.youtube.com/embed/OXC0BbH8mkU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.video}
          ></iframe>
          {/* <iframe
            width="950"
            height="534"
            src="https://www.youtube.com/embed/OXC0BbH8mkU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.video}
          ></iframe> */}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
